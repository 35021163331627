export enum RouteState {
    Utcheckad = 5,
    Forslag = 3,
    Aktiv = 1,
    Avslutad = 7
}

export class RouteStateMap {
    public static RouteStateName = new Map<number, string>([
        [RouteState.Utcheckad, 'Arbetsversion'],
        [RouteState.Forslag, 'Förslag'],
        [RouteState.Aktiv, 'Aktiv'],
        [RouteState.Avslutad, 'Avslutad'],
    ]);
}