import { autoinject, bindable, bindingMode, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { AdminRouteService } from 'components/admin/services/admin-route/admin-route-service';
import { RouteStateMap, RouteTypeMap } from 'components/admin/admin-constants/index';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { LayerEventType } from 'vv-constants/layer-event-type.enum';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { AdminRouteEventItemType } from 'components/admin/admin-constants/event-types/admin-route-event-type.enum';
import { AdminCheckedOutEventItemType } from 'components/admin/admin-constants/event-types/admin-checked-out-event-item-type.enum';

@autoinject()
export class CheckedOut {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) badgeValue: string;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) isActive;

	loading = false;
	items: IRoute[];
	lastFetched: Date;

	pageSize = 10;
	page: number;
	total: number;

	private allItems: IRoute[];
	private reloadDataSubscription: Disposable;
	private layerViewDateChangedSubscription: Disposable;

	constructor(
		private eventAggregator: EventAggregator,
		private adminRouteService: AdminRouteService,
		private applicationRepository: ApplicationRepository) {
	}

	bind() {
		this.badgeValue = '';
	}

	attached(): void {
		this.reloadDataSubscription = this.eventAggregator.subscribe(AdminCheckedOutEventItemType.RELOAD, () => {
			this.updateList();
		});

		this.layerViewDateChangedSubscription = this.eventAggregator.subscribe(LayerEventType.VIEW_DATE_CHANGED, () => {
			this.updateList();
        });

		this.updateList();
	}

	detached(): void {
		this.reloadDataSubscription.dispose();
		this.layerViewDateChangedSubscription.dispose();
	}

	async itemClicked(route: IRoute) {
		// Call service to get the geometry part, then publish to the eventaggretator
		this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE_LOADING);
		const routeWithGeometry = await this.adminRouteService.getCheckedOutRoute(route.FeatureOid, route.RouteType, this.applicationRepository.viewDate);
		this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE, routeWithGeometry);
		this.isActive = false;
	}

	pageChanged($event: any) {
		this.page = $event.detail;
		this.setItems();
	}

	async updateList() {
		this.loading = true;
		this.items =[];
		this.badgeValue = '';
		this.total = 0;
		this.page = 0;

		try {
			this.allItems = await this.adminRouteService.getCheckOuts(this.applicationRepository.viewDate);
			this.lastFetched = new Date();

			if (this.allItems && this.allItems.length) {
				this.total = this.allItems.length;
				this.badgeValue = `${this.total}`;
			}
			else {
				this.badgeValue = '';
			}

			this.setItems();
			this.loading = false;
		}
		catch (error) {
			this.loading = false;
		}
	}

	private setItems(): void {
		if (!this.allItems) {
			return;
		}
		this.items = this.allItems;
	}
}

export class RouteStateValueConverter {
	toView(value?: number) {
		if (RouteStateMap.RouteStateName.has(value)) {
			return RouteStateMap.RouteStateName.get(value);
		}
	}
}

export class RouteTypeValueConverter {
	toView(value?: number) {
		return RouteTypeMap.RouteTypeName.get(value);
	}
}