    
export const SdcDkCatalogueName = "SDC_DK";

export const RfdDkCatalogueName = "RFD_DK";

export const SdcDkCheckedOutCatalogueName = "SDC_DK_UTCHECKAD";

export const SdcDkNsId = 77;

export const RfdDkNsId = 8;

export const SdcDkCheckedOutNsId = 78;