import { autoinject, bindable, bindingMode, Disposable} from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DynamicBlockTypes } from 'components/admin/admin-constants/index';
import * as L from 'leaflet';
import { wktToGeoJSON } from "@terraformer/wkt"
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { Coord, CoordDynamicblock, DynamicBlockList, DynamicBlocksService, IDynamicBlock, Polygon, PolygonDynamicBlock } from 'components/admin/services/dynamic-block-service';
import { AdminDynamicBlockEventItemType } from 'components/admin/admin-constants/event-types/admin-dynamic-block-event-item-type.enum';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';

@autoinject
export class DynamicBlocks{
	@bindable({ defaultBindingMode: bindingMode.twoWay }) isActive;
	
	loading = false;
	dynamicBlockList: DynamicBlockList;
	items: IDynamicBlock[];
	blocksLayer: L.FeatureGroup = new L.FeatureGroup();
	private mapCrs: L.Proj.CRS;
	protected checked = false;
	selected = [];

	blocksLayersChecked: BlockLayer[] = [];

	dynamicBlockType = {
        NVDB: DynamicBlockTypes.NVDB,
		COORD: DynamicBlockTypes.Coord,
		POLYGON: DynamicBlockTypes.Polygon
	}
	
	dynamicblockTypes = [
		{
			id: DynamicBlockTypes.Coord,
			name: 'Coord'
		}, 
		{
			id: DynamicBlockTypes.Polygon,
			name: 'Polygon'
		},
		{
			id: DynamicBlockTypes.NVDB,
			name: 'Nvdb'
		}
	]

	private reloadDataSubscription: Disposable;
    private logger: Logger;
	
	constructor(private dynamicblockService: DynamicBlocksService , 
		private eventAggregator: EventAggregator,
		private applicationRepo: ApplicationRepository,
		private iconService: IconService){

	}
	
	attached():void{
		this.mapCrs = this.applicationRepo.mapCrs;
		this.updateList();

		this.reloadDataSubscription = this.eventAggregator.subscribe(AdminDynamicBlockEventItemType.RELOAD, () => {
			this.updateList();
		});
	}
	
	toggle(event, db): void {
        event.stopPropagation();
        db.expanded = db.expanded ? false : true;
    }

	async updateList() {
		this.loading = true;

		try {
			this.dynamicBlockList = await this.dynamicblockService.getDynamicBlocks();
			this.loading = false;
			this.setItems();
		}
		catch (error) {
			console.log(error);
		}
	}
	private setItems(): void {
		if (!this.dynamicBlockList) {
			return;
		}
		this.removeAllDBFromMap();
		this.items = this.dynamicBlockList.DynamicblockItems;

		this.blocksLayersChecked = [];
		this.items.forEach(db => {
			
			const featureGroup = new L.FeatureGroup;
			//Coord
			if (db.Type == DynamicBlockTypes.Coord) {
				for (let i = 0; i < (db as CoordDynamicblock).Coordblocks.length; i++) {

					const startIcon = L.icon({
						iconUrl: this.iconService.getIcon(IconType.IconMapDd1F6575),
						iconSize: [26, 38],
						iconAnchor: [13, 38]
					});

					const startLatlng = new L.LatLng(parseFloat((db as CoordDynamicblock).Coordblocks[i].X), parseFloat((db as CoordDynamicblock).Coordblocks[i].Y));
					const marker = L.marker(startLatlng, { icon: startIcon, draggable: false }).addTo(featureGroup);
					const radiusOnMarker = L.circle(startLatlng, { radius: (db as CoordDynamicblock).Coordblocks[i].Radius, color: "#1f6575" }).addTo(featureGroup);
					
					const popup = this.getPopUpForMarker(db,(db as CoordDynamicblock).Coordblocks[i], marker);
					marker.bindPopup(popup);

				}
			}
			
			//Polygon
			if (db.Type == DynamicBlockTypes.Polygon) {
				for (let i = 0; i < (db as PolygonDynamicBlock).Polygonblocks.length; i++) {
					const latlngs = this.getPolygonLatLngArray((db as PolygonDynamicBlock).Polygonblocks[i].WKT);
					const polygonInMap = L.polygon(latlngs, { color: "#a0316d" }).addTo(featureGroup);

					const popup = this.getPopUpForPolygon(db, (db as PolygonDynamicBlock).Polygonblocks[i], polygonInMap);
					polygonInMap.bindPopup(popup);
				}
			}
			this.blocksLayersChecked.push({id: db.Id, featureGroup: featureGroup});
		});
		
	}
	
	getPopUpForPolygon(dynamicblock: IDynamicBlock, db: Polygon, polygonInMap: L.Polygon<any>) {
		const contatiner = L.DomUtil.create('div', 'dbPostit');
		const closeBtn = L.DomUtil.create('span', 'close-dbPostit');
		closeBtn.innerText = '-';
		closeBtn.title = 'Fäll ihop information';
		closeBtn.onclick = () => {
			polygonInMap.closePopup();
		};

		const label = L.DomUtil.create('label', 'label-dbPostit pull-left');
		label.innerText = dynamicblock.Comment;
		const type = L.DomUtil.create('p', 'p-dbPostit');
		type.innerHTML =  "Typ: Polygon";

		const stringvalidfrom = dynamicblock.ValidFrom ? dynamicblock.ValidFrom.toString() : '';
		const validfrom = L.DomUtil.create('p', 'p-dbPostit');
		validfrom.innerHTML =  "Giltig fr.o.m: " + stringvalidfrom;

		const stringvalidto = dynamicblock.ValidTo ? dynamicblock.ValidTo.toString() : '';
		const validto = L.DomUtil.create('p', 'p-dbPostit');
		validto.innerHTML =  "Giltig till: " + stringvalidto;
		
		const delay = L.DomUtil.create('p', 'p-dbPostit');
		delay.innerHTML = "Fördröjning: " + db.Delay;
		
		const direction = L.DomUtil.create('p', 'p-dbPostit');
		direction.innerHTML = "Riktning: " + db.Direction;
	
		const editButton = L.DomUtil.create('button', 'btn btn-outline-primary db editButton') as HTMLButtonElement;
		editButton.style.height = "2em";
		editButton.style.lineHeight = "1em";
		editButton.style.color = "#000000";
		editButton.style.backgroundColor = "#ffffff";
		editButton.innerHTML = "<i class='fa fa-pencil'></i>";
		editButton.onclick = () => {
			this.editDynamicBlock(dynamicblock.Id);
		};

		contatiner.appendChild(closeBtn);
		contatiner.appendChild(label);
		contatiner.appendChild(type);
		contatiner.appendChild(validfrom);
		contatiner.appendChild(validto);
		contatiner.appendChild(delay);
		contatiner.appendChild(direction);
		contatiner.appendChild(editButton);

		const popup = L.popup({
			maxHeight: 500,
			maxWidth: 360,
			minWidth: 0,
			autoPan: false,
			closeOnClick: false,
			autoClose: false,
			closeButton: false
		}).setContent(contatiner);

		return popup;
	}
	
	getPopUpForMarker(dynamicblock:IDynamicBlock, db: Coord, marker: L.Marker) {
		const contatiner = L.DomUtil.create('div', 'dbPostit');
		const closeBtn = L.DomUtil.create('span', 'close-dbPostit');
		closeBtn.innerText = '-';
		closeBtn.title = 'Fäll ihop information';
		closeBtn.onclick = () => {
			marker.closePopup();
		};

		const label = L.DomUtil.create('label', 'label-dbPostit pull-left');
		label.innerText = dynamicblock.Comment;
		

		const type = L.DomUtil.create('p', 'p-dbPostit');
		type.innerHTML =  "Typ: Koordinat";

		const stringvalidfrom = dynamicblock.ValidFrom ? dynamicblock.ValidFrom.toString() : '';
		const validfrom = L.DomUtil.create('p', 'p-dbPostit');
		validfrom.innerHTML =  "Giltig fr.o.m: " + stringvalidfrom;

		const stringvalidto = dynamicblock.ValidTo ? dynamicblock.ValidTo.toString() : '';
		const validto = L.DomUtil.create('p', 'p-dbPostit');
		validto.innerHTML =  "Giltig till: " + stringvalidto;

		const x = L.DomUtil.create('p', 'p-dbPostit');
		x.innerHTML =  "X: " + db.X;

		const y = L.DomUtil.create('p', 'p-dbPostit');
		y.innerHTML ="Y: " + db.Y;
		
		const radius = L.DomUtil.create('p', 'p-dbPostit');
		radius.innerHTML = "Radie: " + db.Radius;
		
		const delay = L.DomUtil.create('p', 'p-dbPostit');
		delay.innerHTML = "Fördröjning: " + db.Delay;
		
		const direction = L.DomUtil.create('p', 'p-dbPostit');
		direction.innerHTML = "Riktning: " + db.Direction;
		
	
		const editButton = L.DomUtil.create('button', 'btn btn-outline-primary db editButton') as HTMLButtonElement;
		editButton.style.height = "2em";
		editButton.style.lineHeight = "1em";
		editButton.style.color = "#000000";
		editButton.style.backgroundColor = "#ffffff";
		editButton.innerHTML = "<i class='fa fa-pencil'></i>"
		editButton.onclick = () => {
			this.editDynamicBlock(dynamicblock.Id);
		};

		contatiner.appendChild(closeBtn);
		contatiner.appendChild(label);
		contatiner.appendChild(type);
		contatiner.appendChild(validfrom);
		contatiner.appendChild(validto);
		contatiner.appendChild(x);
		contatiner.appendChild(y);
		contatiner.appendChild(radius);
		contatiner.appendChild(delay);
		contatiner.appendChild(direction);
		contatiner.appendChild(editButton);

		const popup = L.popup({
			maxHeight: 500,
			maxWidth: 360,
			minWidth: 0,
			autoPan: false,
			closeOnClick: false,
			autoClose: false,
			closeButton: false
		}).setContent(contatiner);

		return popup;
	}

	showAllInMapChanged(event){
		this.selected = event.target.checked ? this.items.slice() : [];

		if(this.selected.length === this.items.length){
			this.showAllDBinMap(event);
		}
		else{
			this.removeAllDBFromMap();
		}		
	}

	showAllDBinMap(event) {
		
		this.items.forEach(dynamicblock =>{
			this.showDBinMap(event, dynamicblock);
		});
	}

	removeAllDBFromMap() {
		this.blocksLayersChecked.forEach(checkedLayer => {
			this.applicationRepo.map.removeLayer(checkedLayer.featureGroup);
		});
	}

	async editDynamicBlock(id){
		this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK_LOADING);
		const dynamicblock = await this.dynamicblockService.getDynamicBlock(id);
		this.eventAggregator.publish(AdminDynamicBlockEventItemType.SET_DYNAMICBLOCK, dynamicblock);
	}

	showDBinMap(event, dynamicblock){
		
		if (event.target.checked) {
			this.blocksLayersChecked.forEach(layer =>{
				if(layer.id == dynamicblock.Id){
					this.applicationRepo.map.addLayer(layer.featureGroup);
				}
			});
		}
		else{
			this.blocksLayersChecked.forEach(checkedLayer => {
				if(checkedLayer.id == dynamicblock.Id){
					this.applicationRepo.map.removeLayer(checkedLayer.featureGroup);
				}
			});
			
		}
	}
	
	zoomToBlock(id: string){
		for (const item of this.blocksLayersChecked) {
			if (item.id !== id) {
				continue;
			}

			
			const bounds = item.featureGroup.getBounds();
			const a = 1;

		}
		this.blocksLayersChecked.forEach(cl =>{
			if(cl.id == id){
				this.applicationRepo.map.fitBounds(cl.featureGroup.getBounds(), {padding:[20,20]});
			}
		});
	}

	getPolygonLatLngArray(wkt:string): L.LatLng[]{
		const parse = wktToGeoJSON(wkt);
		const latlngs = ((parse as GeoJSON.Polygon).coordinates);
		const latlngs1: L.LatLng[] = [];

		latlngs[0].forEach(ll => {
			const latlng = new L.LatLng(ll[1], ll[0]);
			latlngs1.push(latlng);
		});

		return latlngs1;
	}
	
}

export class BlockLayer{
	id:string;
	featureGroup: L.FeatureGroup;
}

export class CheckedInValueConverter {
    toView(value?: number) {
        if (value) {
            return 'Incheckad';
        } else {
            return 'Utcheckad';
        }
    }
}
