export enum DynamicBlockTypes {
    Polygon = 1,
	Coord = 2,
	NVDB = 3,
}

export class DynamicBlockTypeMap {
    public static DynamicBlockTypeName = new Map<number, string>([
        [DynamicBlockTypes.NVDB, 'NVDB'],
        [DynamicBlockTypes.Polygon, 'Polygon'],
        [DynamicBlockTypes.Coord, 'Coord'],
    ]);
}