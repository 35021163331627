import { PLATFORM, autoinject } from "aurelia-framework";
import { Router, RouterConfiguration } from 'aurelia-router';
import { HttpClient } from 'aurelia-fetch-client';

import { AppRole } from 'services/authentication/models/app-roles.enum';
import { AuthorizeStep } from './services/authentication/auth-step';
import { ExceptionInterceptor } from './exception-interceptor';
import { Map } from './pages/map/map';

@autoinject()
export class App {
  public router: Router;
  constructor(private httpClient: HttpClient, private interceptor: ExceptionInterceptor) {
    this.httpClient = this.httpClient.configure(x => {
      x.withDefaults({headers : {'Content-Type': 'application/json'}});
      x.withInterceptor(this.interceptor);
    });
  }

  public configureRouter(config: RouterConfiguration, router: Router) {
    config.title = 'Krönt Vägval';

    config.addPipelineStep('authorize', AuthorizeStep);

    config.map([
      {
        route: ['', 'map', 'map/:data?'],
        name: 'map',
        title: 'Vägval Karta',
        // moduleId: './pages/map/map',
        moduleId: PLATFORM.moduleName('pages/map/map'),
        nav: true
      },
      {
        route: ['admin', 'admin/:data?'],
        name: 'admin',
        title: 'Admin',
        // moduleId: './pages/admin/admin',
        moduleId: PLATFORM.moduleName('pages/admin/admin'),
        nav: true,
        settings: {
          requireLogin: true,
          roles: [AppRole.VV_EDITOR, AppRole.VV_COMMITTER,]
        }
      }
    ]);

    this.router = router;
  }
}
