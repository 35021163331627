import { autoinject, LogManager, bindable, bindingMode, Disposable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Logger } from 'aurelia-logging';

import * as moment from 'moment';
import * as L from 'leaflet';

import { FeatureTypeMap, FeatureTypeIdToRouteTypeMap, FeatureValidationErrorMap, ToDoStatusMap } from 'components/admin/admin-constants/index';
import { Alert, AlertModel } from 'components/common/dialog/alert';
import { DialogService } from 'aurelia-dialog';
import { AppRole } from 'services/authentication/models/app-roles.enum';
import { Proj4GeoJSONFeature } from 'proj4leaflet';
import { AdminRouteService } from 'components/admin/services/admin-route/admin-route-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { IPrincipal } from 'services/authentication/models/principal.interface';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { ToDoList } from 'components/admin/services/to-dos/models/to-do-list.model';
import { ToDoItem } from 'components/admin/services/to-dos/models/to-do-item.model';
import { LinkSequenceFeature } from 'components/admin/services/to-dos/models/link-sequence-feature.model';
import { GetLinkSequenceFeatureRequest } from 'components/admin/services/to-dos/models/get-link-sequence-feature-request.model';
import { ToDoNetworkReference } from 'components/admin/services/to-dos/models/to-do-network-reference.model';
import { ToDoStatus } from 'components/admin/services/to-dos/models/to-do-status.model';
import { ToDosService } from 'components/admin/services/to-dos/to-dos-service';
import { AdminRouteEventItemType } from 'components/admin/admin-constants/event-types/admin-route-event-type.enum';
import { AdminToDoEventItemType } from 'components/admin/admin-constants/event-types/admin-todo-event-item-types.enum';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';

@autoinject
export class ToDos {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) badgeValue: string;
    @bindable isActive;

    private route: IRoute;
    private toDoList: ToDoList;
    private selectedItem: ToDoItem;

    private maxChangeDate: moment.Moment;
    private timer: any;
    private logger: Logger;
    private linkSequenceFeatures: LinkSequenceFeature[];

    private linkLayers: L.Layer[] = [];
    private linkLayerGroup: L.FeatureGroup;

    private map: L.Map;
    private mapCrs: L.Proj.CRS = undefined;

    private mapLoadedSubscription: Disposable;
	private reloadDataSubscription: Disposable;
	

	private fetchingTodos: boolean;
	private fetchingRoute: boolean;
	private fetchingNetwork: boolean;
	
	private principal: IPrincipal;

	@computedFrom('fetchingTodos')
	get loading(): boolean {
		return this.fetchingTodos;
	}

	@computedFrom('fetchingRoute', 'fetchingNetwork')
	get loadingRoute(): boolean {
		return this.fetchingRoute || this.fetchingNetwork;
	}

	@computedFrom('principal')
	get isCommitter(): boolean {
		if (!this.principal) {
			return false;
		}
		return this.principal.isInRole(AppRole.VV_COMMITTER)
	}

    constructor(
        private applicationRepository: ApplicationRepository,
        private eventAggregator: EventAggregator,
        private toDosService: ToDosService,
        private adminRouteService: AdminRouteService,
		private dialogService: DialogService,
        private iconService: IconService) {

        this.logger = LogManager.getLogger('ToDos');

        this.mapLoadedSubscription = this.eventAggregator.subscribeOnce(MapEventType.MAP_LOADED, () => {
            this.mapCrs = this.applicationRepository.mapCrs;
            this.map = this.applicationRepository.map;
        });
    }

    attached() {
		if (!this.map) {
            this.map = this.applicationRepository.map;
        }

        if (!this.mapCrs) {
            this.mapCrs = this.applicationRepository.mapCrs;
        }

        this.badgeValue = '';
        this.getToDos();
		
		this.reloadDataSubscription = this.eventAggregator.subscribe(AdminToDoEventItemType.RELOAD, () => {
            this.getToDos();
        });
    }

    detached() {
        this.mapLoadedSubscription.dispose();
		this.reloadDataSubscription.dispose();
		
		this.removeAllLayersFromMap();
    }

	/**
	 * Tab active has changed
	 */
    isActiveChanged() {
        // When tab is deactivated remove all todo objects from map
        if (!this.isActive) {
            this.removeAllLayersFromMap();
        }
    }

    /**
     * Shows or hides network references list for selected toDoItem
     * @param event 
     * @param todoItem 
     */
    toggle(event, todoItem): void {
        event.stopPropagation();
        todoItem.expanded = todoItem.expanded ? false : true;
    }

    /**
     * Triggers event to open the route form with the selected toDoItem
     * @param event 
     * @param toDoItem 
     */
    async toDoItemClicked(event, toDoItem: ToDoItem) {
		if (event) {
			event.stopPropagation();
		}
        
        if (!this.route || !this.selectedItem || this.selectedItem.Id !== toDoItem.Id) {
            this.route = null;

            this.removeNetworkReferencesFromMap();
            this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE_LOADING);
            this.route = await this.getRoute(toDoItem);
            this.selectedItem = toDoItem;
        }

        if (this.route) {
            this.triggerOpenRouteForm();
        }
    }

    /**
     * Shows the route in map
     * @param event 
     * @param toDoItem 
     */
    async showRouteClicked(event, toDoItem: ToDoItem) {
        if (event) {
			event.stopPropagation();
		}


        if (this.route && this.linkLayerGroup && this.selectedItem && this.selectedItem.Id === toDoItem.Id) {
            this.triggerOpenRouteForm();
            return;
        }

        this.route = null;
        this.removeNetworkReferencesFromMap();

        this.route = await this.getRoute(toDoItem);
        this.selectedItem = toDoItem;

        const linkOids: string[] = [];
        if (toDoItem.NetworkReferences && toDoItem.NetworkReferences.length > 0) {
            toDoItem.NetworkReferences.forEach(x => {
                linkOids.push(x.LinkSequenceOid);
            });
        }

        if (this.route) {
            this.triggerOpenRouteForm();
        }

        this.linkSequenceFeatures = await this.getLinkSequenceFeatures(linkOids);
        if (this.linkSequenceFeatures) {
            this.showNetworkReferencesInMap(toDoItem);
        }
    }

    /**
     * Zooms to a network reference in map
     * @param toDoItem 
     * @param link 
     */
    async networkReferenceClicked(toDoItem: ToDoItem, link: ToDoNetworkReference) {
        if (!this.linkLayerGroup || !this.selectedItem || this.selectedItem.Id !== toDoItem.Id) {
            await this.showRouteClicked(null, toDoItem);
        }
        
        // Zoom to feature
        let feature: any = undefined;
        this.linkLayerGroup.eachLayer(outer => {
            (outer as any).eachLayer(inner => {
                if(inner.feature && inner.feature.properties["oid"] === link.LinkSequenceOid) {
                    feature = inner;
                    return false;
                }
            })

            if (feature) {
                return false;
            }
        });

        if (feature) {
            this.map.fitBounds(feature.getBounds().pad(Math.sqrt(2) / 2));
        }
    }


    private resetExpanded() {
        if (this.toDoList && this.toDoList.ToDoItems) {
            this.toDoList.ToDoItems.forEach(x => {
                x["expanded"] = false;
            });
        }
    }

    private async updateList() {
        try {
            let lastChangeDate: string = null;
            if (this.maxChangeDate) {
                lastChangeDate = this.maxChangeDate.toISOString();
            }

            const timeToUpdate: boolean = await this.toDosService.getIsListUpdated(lastChangeDate);
            if (timeToUpdate) {
                this.getToDos();
            }
        }
        catch (error) {
            this.logger.error(error);
        }
    }

    private async getToDos() {
        this.fetchingTodos = true;
        this.badgeValue = '';
        try {
            await this.toDosService.getToDoList().then(toDos => {
                this.removeAllLayersFromMap();
                if (toDos && toDos.ToDoItems.length) {
                    this.toDoList = toDos;
                    this.resetExpanded();
                    this.badgeValue = this.toDoList.ToDoItems.length.toString();
                    // this.maxChangeDate = moment(this.toDoList.ToDoItems.reduce((a, b) => {
                    //     return moment(a.ChangedDate).isAfter(b.ChangedDate) ? a : b;
                    // }).ChangedDate);
                }
            });
            this.fetchingTodos = false;
        }
        catch (error) {
            this.fetchingTodos = false;
            this.logger.error(error);
        }
    }

    private removeAllLayersFromMap() {
        if (!this.map) {
            return;
        }

        this.removeNetworkReferencesFromMap();
    }

    private removeNetworkReferencesFromMap() :void {
        if (!this.map) {
            return;
        }

        if (this.linkLayers) {
            this.linkLayers = [];
        }

        if (this.linkLayerGroup) {
            this.linkLayerGroup.removeFrom(this.map);
            this.linkLayerGroup = null;
        }
    }

    private showNetworkReferencesInMap(toDoItem: ToDoItem): void {
        this.removeNetworkReferencesFromMap();

        if (!this.linkSequenceFeatures || this.linkSequenceFeatures.length < 1) {
            return;
        }

        this.linkSequenceFeatures.forEach(linkSeq => {

            const geojson: Proj4GeoJSONFeature = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: linkSeq.Geometry[0].map(
                        x => { return [x.X, x.Y]; })
                },
                crs: {
                    type: 'name',
                    properties: {
                        name: 'urn:ogc:def:crs:EPSG::3006'
                    }
                },
                properties: {
                    oid: linkSeq.Oid,
                    color: '#F9D600',
                    weight: 4
                }
            };

            const linkLayer = L.Proj.geoJson(geojson, {
                style: (feature: any) => {
                    return {
                        color: feature.properties.color,
                        weight: feature.properties.weight
                    };
                }
            });

            const startLatlng = (this.mapCrs as any).unproject({ x: linkSeq.CenterPoint.X, y: linkSeq.CenterPoint.Y });
            const linkErrorIcon = L.icon({
                iconUrl: this.iconService.getIcon(IconType.IconViaError),
                iconSize: [26, 38],
                iconAnchor: [13, 38]
            });

            const marker = L.marker(startLatlng, { icon: linkErrorIcon, draggable: false }).addTo(linkLayer);
            const todoLinkRef = toDoItem.NetworkReferences.find(x => x.LinkSequenceOid === linkSeq.Oid);
            if(todoLinkRef) {
                marker.bindTooltip(new ValidationErrorMessageValueConverter().toView(todoLinkRef.Message)).openTooltip();
            }

            this.linkLayers.push(linkLayer);
        });

        this.linkLayerGroup = new L.FeatureGroup(this.linkLayers);
        this.linkLayerGroup.addTo(this.map);
    }
    
    private async getLinkSequenceFeatures(linkOids: string[]): Promise<LinkSequenceFeature[]> {
        try {
			this.fetchingNetwork = true;
            const request: GetLinkSequenceFeatureRequest = {
                LinkOids: linkOids,
                ViewDate: Number(this.applicationRepository.viewDate)
            };

			const features = await this.toDosService.getLinkSequenceFeature(request);
			this.fetchingNetwork = false;
			return features;
        } 
        catch (error) {
			this.fetchingNetwork = false;
            this.logger.error(error);
            return null;
        }
    }

    private async getRoute(toDoItem: ToDoItem): Promise<IRoute> {
        if (!toDoItem) {
            return null;
        }

		this.fetchingRoute = true;

        const featureOid = toDoItem.FeatureOid;
        const routeType = FeatureTypeIdToRouteTypeMap.RouteType.get(toDoItem.FeatureTypeId);
        const viewDate = this.applicationRepository.viewDate;

        try {
            if (toDoItem.Status === ToDoStatus.CheckedOut) {
				const route = await this.adminRouteService.getCheckedOutRoute(featureOid, routeType, viewDate);
				this.fetchingRoute = false;
				return route;
            }
            else {
				const route = await this.adminRouteService.getCheckedInRoute(featureOid, routeType, viewDate);
				this.fetchingRoute = false;
				return route;
            }
        } 
        catch (error) {
			this.fetchingRoute = false;
            this.logger.error(error);
            return null;
        }
    }

    private triggerOpenRouteForm(): void {
        if (this.route) {
            this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE, this.route);
        }
    }

	ignoreToDoItemDialog(event, toDoItem: ToDoItem) {
		if (event) {
			event.stopPropagation();
		}
		
		const alertModel: AlertModel = {
			Mode: "question",
			ErrorMessage: "Vill ignonera " + toDoItem.Name + "?",
			ExtraInfo: toDoItem.Name + " tas bort från jobblistan, operationen kan inte ångras.",
			AbortText: "Avbryt",
			ConfirmText: "Ignonera",
			ConfirmButtonColor: "green"
		};

		this.dialogService.open({ viewModel: Alert, model: alertModel }).whenClosed(response => {
			if (!response.wasCancelled) {
				this.ignoreItem(toDoItem.FeatureOid)
			}
		});
	}

	private async ignoreItem(featureOid: string) {
		try {
			await this.toDosService.updateStatus(featureOid, ToDoStatus.Active);
			this.eventAggregator.publish(AdminToDoEventItemType.RELOAD);
		}
		catch (error) {
			this.logger.error(error);
		}
	}

}

export class FeatureTypeValueConverter {
    toView(value?: number) {
        if (FeatureTypeMap.FeatureTypeName.has(value)) {
            return FeatureTypeMap.FeatureTypeName.get(value);
        } else {
            return 'Okänd';
        }
    }
}

export class StatusTextValueConverter {
    toView(value?: number) {
        if (ToDoStatusMap.ToDoStatusName.has(value)) {
            return ToDoStatusMap.ToDoStatusName.get(value);
        } else {
            return "Ogiltigt värde";
        }
    }
}

export class ValidationErrorMessageValueConverter {
    toView(value?: string) {
        if (FeatureValidationErrorMap.ErrorMessages.has(value)) {
            return FeatureValidationErrorMap.ErrorMessages.get(value);
        } else {
            return value;
        }
    }
}
