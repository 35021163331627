
import { RouteType } from './route-types';

export enum FeatureTypeId {
    TimmerLed = 1,
    RekommenderadLed = 57,
    FacitRutt = 91,
    TpLed = 92
}

export class FeatureTypeIdToRouteTypeMap {
    public static RouteType = new Map<number, number>([
        [FeatureTypeId.TimmerLed, RouteType.TimmerLed],
        [FeatureTypeId.RekommenderadLed, RouteType.RekommenderadLed],
        [FeatureTypeId.FacitRutt, RouteType.FacitRutt],
        [FeatureTypeId.TpLed, RouteType.TpLed]
    ]);
}

export class FeatureTypeMap {
    public static FeatureTypeName = new Map<number, string>([
        [FeatureTypeId.TimmerLed, 'Timmerled'],
        [FeatureTypeId.RekommenderadLed, 'Rekommenderad led'],
        [FeatureTypeId.FacitRutt, 'Facitrutt'],
        [FeatureTypeId.TpLed, 'TP-led']
    ]);
}

export class FeatureValidationErrorMap {
    public static ErrorMessages = new Map<string, string>([
        ['FeatureInstanceExistsOnNetworkValidation', 'Företeelseinstansen har en ogiltig länkreferens'],
        ['FeatureInstanceExistsOnValidNetworkValidation', 'Företeelseinstansen har en ogiltig länkreferens på det aktuella nätet']
    ]);
}
