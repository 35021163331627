export enum DynamicBlockState {
    Utcheckad = 5,
    Forslag = 3,
    Aktiv = 1,
    Avslutad = 7
}

export class DynamicBlockStateMap {
    public static DynamicBlockStateName = new Map<number, string>([
        [DynamicBlockState.Utcheckad, 'Arbetsversion'],
        [DynamicBlockState.Forslag, 'Förslag'],
        [DynamicBlockState.Aktiv, 'Aktiv'],
        [DynamicBlockState.Avslutad, 'Avslutad'],
    ]);
}