import { autoinject, bindable, bindingMode, Disposable} from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FeatureTypeMap, FeatureTypeIdToRouteTypeMap} from 'components/admin/admin-constants/index';
import { Logger } from 'aurelia-logging';
import moment from "moment";
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { UnvalidFeaturesList } from 'components/admin/services/to-dos/models/unvalid-feature-list.model';
import { UnvalidFeature } from 'components/admin/services/to-dos/models/unvalid-feature.model';
import { ToDosService } from 'components/admin/services/to-dos/to-dos-service';
import { AdminRouteService } from 'components/admin/services/admin-route/admin-route-service';
import { TimeVersion } from 'components/admin/services/to-dos/models/time-version.model';
import { IRoute } from 'components/admin/services/admin-route/models/route-interface';
import { AdminRouteEventItemType } from 'components/admin/admin-constants/event-types/admin-route-event-type.enum';
import { AdminUnvalidFeaturesEventItemType } from 'components/admin/admin-constants/event-types/admin-unvalid-features-event-item-type.enum';

@autoinject()
export class UnvalidFeatures{
	@bindable({ defaultBindingMode: bindingMode.twoWay }) badgeValue: string;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) isActive;

	loading = false;

	private unvalidFeatureList: UnvalidFeaturesList;
	items: UnvalidFeature[];
	test: string;
	total: number;
	fetchingRoute: boolean;
	private reloadDataSubscription: Disposable;
    private logger: Logger;
	
	constructor(
		private toDosService: ToDosService, 
		private eventAggregator: EventAggregator,
		private adminRouteService: AdminRouteService,
		private applicationRepository: ApplicationRepository ) {
	}

	bind() {
		this.badgeValue = '';
	}

	attached(): void {
		this.reloadDataSubscription = this.eventAggregator.subscribe(AdminUnvalidFeaturesEventItemType.RELOAD, () => {
			this.updateList();
		});
		
		this.updateList();
	}

	detached(): void {

		if (this.reloadDataSubscription) {
			this.reloadDataSubscription.dispose();
			this.reloadDataSubscription = undefined;
		}
	}
	
	/**
     * Shows or hides network references list for selected featureGroup
     * @param event 
     * @param featureGroup 
     */
	toggle(event, featureGroup): void {
        event.stopPropagation();
        featureGroup.expanded = featureGroup.expanded ? false : true;
    }

	async updateList() {
		this.loading = true;
		this.total = 0;
		this.badgeValue = '';

		try {
			const viewDate = this.applicationRepository.viewDate;
			this.unvalidFeatureList = await this.toDosService.GetNoValidFeatures(viewDate);
			this.unvalidFeatureList.FeatureItems.forEach(async f => {
				const statusFeature = await this.toDosService.GetNoValidFeaturesFromCheckedOutCatalogue(f.FeatureTypeId, f.Oid);
				if(statusFeature != null){
					f.Status = "Utcheckad";
				}
				else{
					f.Status = "Incheckad";
				}
			});

			this.loading = false;
			this.total = this.unvalidFeatureList.FeatureItems.length;
			this.badgeValue =  `${this.total}`;
			this.setItems();
		}
		catch (error) {
			console.log(error);
		}
	}
	private setItems(): void {
		if (!this.unvalidFeatureList) {
			return;
		}
		this.items = this.unvalidFeatureList.FeatureItems;
	}
  
	
	async itemClicked(featureItem : UnvalidFeature, tv : TimeVersion): Promise<IRoute> {
        if (!featureItem) {
            return null;
        }

		this.fetchingRoute = true;
		const routeType = FeatureTypeIdToRouteTypeMap.RouteType.get(featureItem.FeatureTypeId);
		
		const dateString  = this.setDateString(tv.ValidPeriod.EndDuration);
	
        try { 
			if (featureItem.Status == "Utcheckad") {
				this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE_LOADING);
				const routeWithGeometry = await this.adminRouteService.getCheckedOutRoute(featureItem.Oid, routeType, dateString);
				this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE, routeWithGeometry);
			}
			else {
				this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE_LOADING);
				const routeWithGeometry = await this.adminRouteService.getCheckedInRoute(featureItem.Oid, routeType, dateString);
				this.eventAggregator.publish(AdminRouteEventItemType.SET_ROUTE, routeWithGeometry);
			}
		} 
        catch (error) {
			this.fetchingRoute = false;
            this.logger.error(error);
            return null;
		}
	}
	
	private setDateString(dateString : string) {
		const validTo = moment(dateString, 'YYYYMMDD');
		validTo.subtract(1, 'days');
		return validTo.format('YYYYMMDD');
	}
	
}
export class FeatureValueConverter {
    toView(value?: number) {
        if (FeatureTypeMap.FeatureTypeName.has(value)) {
            return FeatureTypeMap.FeatureTypeName.get(value);
        } else {
            return 'Okänd';
        }
    }
}	

export class GroupByValueConverter{
	toView(array, property){
		const groups = new Map();
		for(const item of array){
			const key = item[property];
			let group  = groups.get(key);
			if(!group){
				group = {key, items: []};
				groups.set(key,group);
			}
			group.items.push(item);
		}
		return Array.from(groups.values());
	}
}

export class OrderByValueConverter{
	toView(array,property, direction = 'desc'){
		array = array.slice(0);
		const directionFactor = direction == 'asc' ? -1 : 1;
		array.sort((item1, item2) => {
			const value1 = item1[property];
			const value2 = item2[property];

			const year1 = value1[0].ValidPeriod.EndDuration.substring(0, 4);
			const month1 = value1[0].ValidPeriod.EndDuration.substring(4, 6);
			const day1 = value1[0].ValidPeriod.EndDuration.substring(6, 8);
			const date1 = new Date(year1, month1 - 1, day1);

			const year2 = value2[0].ValidPeriod.EndDuration.substring(0, 4);
			const month2 = value2[0].ValidPeriod.EndDuration.substring(4, 6);
			const day2 = value2[0].ValidPeriod.EndDuration.substring(6, 8);
			const date2 = new Date(year2, month2 - 1, day2);

			if(date1 < date2){
				return directionFactor;
			} else if(date1 > date2){
				return -directionFactor;
			} else{
				return 0;
			}
		});
		return array;
	}
}



