export enum RouteType {
    FacitRutt = 0,
    RekommenderadLed = 1,
    TimmerLed = 2,
    TpLed = 3
}

export class RouteTypeMap {
    public static RouteTypeName = new Map<number, string>([
        [RouteType.FacitRutt, 'Facitrutt'],
        [RouteType.RekommenderadLed, 'Rekommenderad led'],
        [RouteType.TimmerLed, 'Timmerled'],
        [RouteType.TpLed, 'TP-led'],
    ]);
}