import { autoinject, bindable, bindingMode, Disposable} from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { wktToGeoJSON } from "@terraformer/wkt"
import * as L from 'leaflet';
import moment from "moment";
import { FacitDifference, RouteDifferenceService } from 'components/admin/services/route-difference-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { AdminDifferencesEventItemType } from 'components/admin/admin-constants/event-types/admin-differences-event-item-type.enum';

@autoinject
export class RouteDifferences{
	@bindable({ defaultBindingMode: bindingMode.twoWay }) isActive;

	loading = false;
	differences: FacitDifference[] = [];
	private mapCrs;
	private segment: L.Polyline[] = [];
	segmentShow: boolean[] = [];
	private newRoute: L.Polyline;
	private oldRoute: L.Polyline;
	oldRouteShow = false;
	newRouteShow = false;
	private diffCircleShow: boolean;
	private diffCircle: L.Circle;
	private to:number = parseInt(this.applicationRepo.viewDate);
	private from:number = parseInt(this.applicationRepo.viewDate);
	private reloadDataSubscription: Disposable;

	constructor(private differenceService: RouteDifferenceService,
		private eventAggregator: EventAggregator,
		private applicationRepo: ApplicationRepository){

		}
	attached():void{
		this.mapCrs = this.applicationRepo.mapCrs;
		this.updateList();

		this.reloadDataSubscription = this.eventAggregator.subscribe(AdminDifferencesEventItemType.RELOAD, () => {
			this.updateList();
        });
	}
	detached(): void  {
		this.reloadDataSubscription.dispose();
		this.clearMap();
    }
	isActiveChanged() {
		if (!this.isActive) {
			this.clearMap();
		}
	}
	toDateChanged(event: CustomEvent): void {
		if (!event.detail) {
			this.to = null;
			return;
		}
		const date = (event.detail as Date);
		this.to = +moment(date).format('YYYYMMDD');
	}

	fromDateChanged(event: CustomEvent): void {
		if (!event.detail) {
			this.from = null;
			return;
		}
		const date = (event.detail as Date);
		this.from = +moment(date).format('YYYYMMDD');
	}
	async updateList(): Promise<void> {
		this.loading = true;
		try {
			this.clearMap();
			this.differences = await this.differenceService.getDifferences(this.from, this.to);
			this.loading = false;
		}
		catch (error) {
			console.log(error);
		}
	}

	clearMap(): void {
		if (this.diffCircle != null) {
			this.applicationRepo.map.removeLayer(this.diffCircle);
			this.diffCircleShow = false;
		}
		if (this.oldRoute != null) {
			this.applicationRepo.map.removeLayer(this.oldRoute);
			this.oldRouteShow = false;
		}
		if (this.newRoute != null) {
			this.applicationRepo.map.removeLayer(this.newRoute);
			this.newRouteShow = false;
		}
		if (this.segment.length > 0) {
			for (let i = 0; i < this.segment.length; i++) {
				this.applicationRepo.map.removeLayer(this.segment[i]);
			}
			
		}
		if(this.differences && this.differences.length > 0){
			this.differences.forEach(x => {
				(x as any).diffCircleShow = false;
				(x as any).oldRouteShow = false;
				(x as any).newRouteShow = false;
				x.RouteDifferences.forEach(diff => {
					(diff as any).show = false;

				});
			});
		}
	}

	toggle(event, diff): void {
        event.stopPropagation();
		diff.expanded = diff.expanded ? false : true;
	}

	toggleAgain(event, item): void {
        event.stopPropagation();
		item.expanded = item.expanded ? false : true;
	}
		
	zoomToNewRoad(event, diff, index, geoNew: string): void {
		event.stopPropagation();
		diff[index].newRouteShow = diff[index].newRouteShow ? false : true;
		this.newRouteShow = diff[index].newRouteShow;

		for (let i = 0; i < diff.length; i++) {
			if(i != index){
				diff[i].newRouteShow = false;
			}
		}

		if (this.newRoute != null) {
			this.applicationRepo.map.removeLayer(this.newRoute);
		}
		if (this.newRouteShow) {
			const latlngsNew = this.getPolylineLatLngArray(geoNew);
			this.newRoute = null;
			this.newRoute = L.polyline(latlngsNew, { color: 'blue' });
			this.applicationRepo.map.addLayer(this.newRoute);

			this.applicationRepo.map.fitBounds(this.newRoute.getBounds());
		}
	}

	zoomToOldRoad(event, diff, index, geoOld:string): void {
		event.stopPropagation();
		diff[index].oldRouteShow = diff[index].oldRouteShow ? false : true;
		this.oldRouteShow = diff[index].oldRouteShow;
		for (let i = 0; i < diff.length; i++) {
			if(i != index){
				diff[i].oldRouteShow = false;
			}
		}
		if(this.oldRoute != null){
			this.applicationRepo.map.removeLayer(this.oldRoute);
		}

		if (this.oldRouteShow) {
			const latlngsOld = this.getPolylineLatLngArray(geoOld);
			this.oldRoute = null;
			this.oldRoute = L.polyline(latlngsOld, { color: 'red' });
			this.applicationRepo.map.addLayer(this.oldRoute);

			this.applicationRepo.map.fitBounds(this.oldRoute.getBounds());
		}
	}

	showSegment(event, item, index:number): void {
		event.stopPropagation();
		item.show = item.show ? false : true;
		this.segmentShow[index] = item.show;
		
		if (this.segment[index] != null) {
			this.applicationRepo.map.removeLayer(this.segment[index]);
		}
		if (this.segmentShow[index]) {
			this.segment[index] = null;
			const latlngs = this.getPolylineLatLngArray(item.geometry);
			this.segment[index] = L.polyline(latlngs, { color: 'green', weight: 10 });

			this.applicationRepo.map.addLayer(this.segment[index]);
		}
	}

	zoomToDiff(event, diff, index, geoOld: string, geoNew: string): void {
		event.stopPropagation();
		diff[index].diffCircleShow = diff[index].diffCircleShow ? false : true;
		this.diffCircleShow = diff[index].diffCircleShow;
		
		for (let i = 0; i < diff.length; i++) {
			if(i != index){
				diff[i].diffCircleShow = false;
			}
		}

		if (this.diffCircle != null) {
			this.applicationRepo.map.removeLayer(this.diffCircle);
		}

		if (this.diffCircleShow) {
			const latlngOld = this.getPolylineLatLngArray(geoOld);
			const latlngNew = this.getPolylineLatLngArray(geoNew);
			
			const uniqueResultOld = this.findUniqueResults(latlngOld, latlngNew);
			const uniqueResultNew = this.findUniqueResults(latlngNew, latlngOld);
			
			let unique = [];
			if(uniqueResultNew.length > 0){
				unique = uniqueResultNew;
			}
			else{
				unique = uniqueResultOld;
			}

			const radius = this.getCircleRadius(uniqueResultOld, uniqueResultNew);
			
			this.diffCircle = null;
			this.diffCircle = L.circle(unique[Math.round((unique.length - 1) / 2)], radius, { color: 'green', fill: false });
			this.applicationRepo.map.addLayer(this.diffCircle);
			this.applicationRepo.map.fitBounds(this.diffCircle.getBounds());
		}

	}

	getPolylineLatLngArray(wkt:string): L.LatLng[] {
		
		const parse = wktToGeoJSON(wkt);

		const latlngs = ((parse as GeoJSON.LineString).coordinates);
		const latlngs1: L.LatLng[] = [];

		latlngs.forEach(ll => {
			const latlng = this.mapCrs.unproject({ x: ll[0], y: ll[1] });
			latlngs1.push(latlng);
		});

		return latlngs1;
	}

	checkRoutes(diff: FacitDifference): boolean {
		if(diff.OldRouteGeometry.localeCompare(diff.NewRouteGeometry) == 0){
			return false;
		}
		else{
			return true;
		}
	}

	findUniqueResults(routeOne: L.LatLng[], routeTwo: L.LatLng[]): L.LatLng[] {
		const uniqueResult = routeOne.filter((obj) => {
			return !routeTwo.some((obj2) => {
				return obj.lat == obj2.lat && obj.lng == obj2.lng;
			});
		});
		return uniqueResult;
	}

	getCircleRadius(uniqueResultOld: L.LatLng[], uniqueResultNew: L.LatLng[] ): number{
		let distanceOld = 0;
			if (uniqueResultOld.length >= 1) {
				distanceOld = uniqueResultOld[0].distanceTo(uniqueResultOld[uniqueResultOld.length - 1]);
			}
			let distanceNew = 0;
			if (uniqueResultNew.length >= 1) {
				distanceNew = uniqueResultNew[0].distanceTo(uniqueResultNew[uniqueResultNew.length - 1]);
			}
			let radius2use = 0
			if (distanceOld == 0 || distanceNew == 0) {
				radius2use = distanceNew + distanceOld;
			}
			else {
				radius2use = (distanceNew + distanceOld) / 2;
			}
			const minRadius = 20;
			if(minRadius < radius2use){
				return radius2use;	
			}
			else{
				return minRadius;
			}
			
	}
	
}